<template>
  <div class="package">
    <a-card title="套餐管理">
      <a-form-model
        layout="inline"
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="订单编号">
          <a-input
            v-model="formInline.user"
            placeholder="请输入订单编号"
            style="width: 250px"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="客户名称">
          <a-input
            v-model="formInline.password"
            placeholder="请输入客户名称"
            style="width: 250px"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="套餐类型">
          <a-select
            v-model="formInline.type"
            placeholder="请选择套餐类型"
            style="width: 250px"
          >
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option :value="1"> 计次套餐 </a-select-option>
            <a-select-option :value="2"> 定期套餐 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
        </a-form-model-item>
      </a-form-model>
      <div style="height: 25px"></div>
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <span slot="index" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <span slot="action" slot-scope="text, record" bordered size="small">
          <span @click="toBuyAgain(record)">套餐续费</span>
        </span>
      </a-table>
      <a-pagination />
    </a-card>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "订单编号",
    dataIndex: "age",
  },
  {
    title: "客户名称",
    dataIndex: "address",
  },
  {
    title: "联系人",
    dataIndex: "address",
  },
  {
    title: "联系电话",
    dataIndex: "address",
  },
  {
    title: "套餐内容",
    dataIndex: "address",
  },
  {
    title: "套餐价格",
    dataIndex: "address",
  },
  {
    title: "支付方式",
    dataIndex: "address",
  },
  {
    title: "套餐类型",
    dataIndex: "address",
  },
  {
    title: "购买时间",
    dataIndex: "address",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "package",
  data() {
    return {
      formInline: {
        user: "",
        password: "",
        type: "",
      },
      data: [],
      columns,
    };
  },
  methods: {
    //查询
    handleSubmit(e) {
      console.log(this.formInline);
    },
    //套餐续费
    toBuyAgain(record) {},
  },
};
</script>

<style lang="less" scoped>
.package {
  padding: 20px;
}
</style>